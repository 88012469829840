import React from "react"
import moment from "moment"

export default function HoursWrapper({ hours }) {
  return (
    <div>
      {moment(hours.start).format("DD.MM.YYYY HH:mm")}-
      {moment(hours.end).format("DD.MM.YYYY HH:mm")}
    </div>
  )
}

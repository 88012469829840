import React from "react"
import { Link } from "gatsby"
import AdminLayout from "../../components/adminPanelLayout"
import RenderTestComp from "../../components/RenderTestComp"
import ContextApiTestComponent from "../../components/ContextApiTestComponent"
import SEO from "../../components/seo"
import { ScheduleProvider } from "../../ScheduleContext"

const AdminPanel = () => (
  <AdminLayout>
    <SEO title="Page two" />
    <h1>canceled</h1>
    <RenderTestComp />
    <ScheduleProvider>
      <ContextApiTestComponent></ContextApiTestComponent>
    </ScheduleProvider>
  </AdminLayout>
)

export default AdminPanel

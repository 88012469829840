import React from "react"
import styled from "styled-components"
import HoursWrapper from "./HoursWrapper"

const TestStyled = styled.div`
  border: 1px solid red;
`
export default function ChildRenderTestComp({ config, componentKey }) {
  return (
    <div>
      {config.workingHours ? (
        <TestStyled>
          {config.workingHours.map((e, i) => {
            return (
              <>
                <HoursWrapper hours={e}></HoursWrapper>
                <div key={i}>
                  <span>{e.start.getFullYear()}</span>
                  <button onClick={() => config.onClickTest(config)}>
                    click
                  </button>
                </div>
              </>
            )
          })}
        </TestStyled>
      ) : (
        ""
      )}
    </div>
  )
}

import React, { useState, createContext } from "react"
import moment from "moment"
import { sendRequest } from "./services/requestTool"

export const ScheduleContext = createContext()
export const ScheduleActionsContext = createContext()

export const ScheduleProvider = props => {
  // useEffect(() => {
  //   fetchData().then(res => {
  //     setCurrentMonthSchedule(dataForComponents)
  //   })
  // }, [])

  const getMonthDaysArray = function (date) {
    const tmpArray = []

    for (
      let i = 1;
      i <= new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      i++
    ) {
      tmpArray.push(new Date(date.getFullYear(), date.getMonth(), i))
    }

    return tmpArray
  }

  // TODO: metoda getFakeApi response bedzie zastapiona odpowiedzia z api
  // const getFakeApiResponse = function (date) {
  //   const currentMonth = []
  //   for (
  //     let i = 1;
  //     i <= new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  //     i++
  //   ) {
  //     const tmpDate = new Date(date.getFullYear(), date.getMonth(), i)
  //     currentMonth.push(
  //       fakeDataFromApi.filter(e => moment(e.date).isSame(tmpDate, "day"))[0] ||
  //         getDefaultArrayItem(tmpDate)
  //     )
  //   }

  //   setCurrentMonthSchedule(currentMonth)

  //   const dataToAllFetchedSchedule = {
  //     date: date,
  //     data: currentMonth,
  //   }

  //   setAllFetchedScheduleData([
  //     ...allFetchedScheduleData,
  //     dataToAllFetchedSchedule,
  //   ])
  // }

  function fetchData(date) {
    const month = date.getMonth()
    const year = date.getFullYear()
    const lastDay = new Date(year, month, 0).getDate()

    const startMoment = moment()
      .set("month", month)
      .set("year", year)
      .set("date", 1)

    const endMoment = moment()
      .set("month", month)
      .set("year", year)
      .set("date", lastDay)

    return new Promise((resolve, reject) => {
      const urlEndpoint = new URL("http://spoomyfly.com/api/Planner/calendar")
      urlEndpoint.searchParams.append(
        "dateFrom",
        startMoment.format("YYYY-MM-DD")
      )
      urlEndpoint.searchParams.append("dateTo", endMoment.format("YYYY-MM-DD"))
      sendRequest({
        url: urlEndpoint.href,
      }).then(res => {
        const data = []
        res.data.forEach(element => {
          element.workerSmallDetails.forEach(e => {
            const tmp = e.workingHours
            e.workingHours = [tmp]
          })
          data.push(element)
        })
        const filledData = fillEmptyDays(date, data)

        setCurrentMonthSchedule(filledData)

        const dataToAllFetchedSchedule = {
          date: date,
          data: filledData,
        }

        setAllFetchedScheduleData([
          ...allFetchedScheduleData,
          dataToAllFetchedSchedule,
        ])
        console.log(allFetchedScheduleData)
      })
      // setTimeout(() => {
      //   console.log("fake:", getFakeApiResponse(date))
      // }, 1500)
    })
  }
  function fillEmptyDays(date, apiList) {
    const listToReturn = []
    const mothArray = getMonthDaysArray(date)

    mothArray.forEach(element => {
      const tmp = apiList.find(e => moment(e.date).isSame(element, "day"))
      if (tmp) {
        listToReturn.push(tmp)
      } else {
        listToReturn.push({ date: element, workerSmallDetails: [] })
      }
    })

    return listToReturn
  }
  function cellChangesListener(date, name, isChange) {}

  function updateSchedule(e, worker) {
    console.log(
      "OUTPUT ~ file: ScheduleContext.js ~ line 128 ~ updateSchedule ~ worker",
      worker
    )
    console.log(
      "OUTPUT ~ file: ScheduleContext.js ~ line 128 ~ updateSchedule ~ e",
      e
    )
    // let newScheduleAfterChanges = currentMonthSchedule.map(s => {
    //   if (moment(s.date).isSame(e, "day")) {
    //     let tmp = s
    //     tmp.workerSmallDetails = tmp.workerSmallDetails.map(w => {
    //       if (w.name == worker.name) {
    //         const tmpDate = new Date(e)
    //         w.workingHours = [
    //           {
    //             start: moment(tmpDate)
    //               .set("hours", 9)
    //               .set("minutes", 0)
    //               .toDate(),
    //             end: moment(tmpDate)
    //               .set("hours", 17)
    //               .set("minutes", 0)
    //               .toDate(),
    //           },
    //         ]
    //       }
    //       return w
    //     })
    //     return tmp
    //   }
    //   return s
    // })
    // setCurrentMonthSchedule(newScheduleAfterChanges)
  }

  function saveChangesInSchedule(date, name, newHours) {
    console.log(
      "OUTPUT ~ file: ScheduleContext.js ~ line 157 ~ saveChangesInSchedule ~ newHours",
      newHours
    )
    //TODO: stan nie jest aktualizowny, trzeba skorzystac z setCurrentMonthSchedule
    const newMonthSchedule = currentMonthSchedule.map(e => {
      if (e.date === date) {
        e.workerSmallDetails.find(
          w => w.name === name
        ).workingHours = newHours.map(e => {
          return {
            startMinutes: parseInt(moment(e.start).format("mm")),
            startHours: parseInt(moment(e.start).format("HH")),
            finishHours: parseInt(moment(e.end).format("HH")),
            finishMinutes: parseInt(moment(e.end).format("mm")),
          }
        })
      }
      return e
    })
    console.log(
      "OUTPUT ~ file: ScheduleContext.js ~ line 177 ~ saveChangesInSchedule ~ newMonthSchedule",
      newMonthSchedule
    )
    setCurrentMonthSchedule(currentMonthSchedule)
  }

  function getData(date) {
    const fetchedBeforeMonthData = allFetchedScheduleData.filter(
      e => e.date && moment(e.date).isSame(date, "month")
    )[0]
    if (!fetchedBeforeMonthData) {
      setCurrentMonthSchedule(null)
      fetchData(date)
    } else {
      setCurrentMonthSchedule(fetchedBeforeMonthData.data)
    }
  }

  const requestedDate = new Date(2021, 1, 5)

  const daysInCurrentMonthCounter = new Date(
    requestedDate.getFullYear(),
    requestedDate.getMonth() + 1,
    0
  ).getDate()

  const fakeDataFromApi = [
    {
      date: new Date(2021, 1, 2),
      workers: [
        {
          name: "Kasia",
          hours: [],
        },
        {
          name: "Basia",
          leave: true,
          hours: [],
        },
        {
          name: "Tomek",
          hours: [
            {
              start: new Date(2021, 1, 2, 9, 0, 0),
              end: new Date(2021, 1, 2, 11, 0, 0),
            },
            {
              start: new Date(2021, 1, 2, 15, 0, 0),
              end: new Date(2021, 1, 2, 18, 0, 0),
            },
          ],
        },
      ],
    },
    {
      date: new Date(2021, 1, 15),
      workers: [
        {
          name: "Kasia",
          hours: [],
        },
        {
          name: "Basia",
          leave: true,
          hours: [],
        },
        {
          name: "Tomek",
          hours: [
            {
              start: new Date(2021, 1, 15, 9, 0, 0),
              end: new Date(2021, 1, 15, 11, 0, 0),
            },
            {
              start: new Date(2021, 1, 15, 15, 0, 0),
              end: new Date(2021, 1, 15, 18, 0, 0),
            },
          ],
        },
      ],
    },
    {
      date: new Date(2021, 1, 22),
      workers: [
        {
          name: "Kasia",
          hours: [
            {
              start: new Date(2021, 1, 22, 9, 0, 0),
              end: new Date(2021, 1, 22, 11, 0, 0),
            },
            {
              start: new Date(2021, 1, 22, 15, 0, 0),
              end: new Date(2021, 1, 22, 18, 0, 0),
            },
          ],
        },
        {
          name: "Basia",
          leave: true,
          hours: [],
        },
        {
          name: "Tomek",
          hours: [],
        },
      ],
    },
  ]

  const getDefaultArrayItem = date => {
    return {
      date: date,
      workers: [
        {
          name: "Kasia",
          hours: [],
        },
        {
          name: "Basia",
          leave: true,
          hours: [],
        },
        {
          name: "Tomek",
          hours: [],
        },
      ],
    }
  }

  const dataForComponents = []

  for (let i = 1; i < daysInCurrentMonthCounter + 1; i++) {
    const tmpDate = new Date(
      requestedDate.getFullYear(),
      requestedDate.getMonth(),
      i
    )
    dataForComponents.push(
      fakeDataFromApi.filter(e => moment(e.date).isSame(tmpDate, "day"))[0] ||
        getDefaultArrayItem(tmpDate)
    )
  }
  const [allFetchedScheduleData, setAllFetchedScheduleData] = useState([])
  const [currentMonthSchedule, setCurrentMonthSchedule] = useState(null)

  return (
    <ScheduleContext.Provider value={currentMonthSchedule}>
      <ScheduleActionsContext.Provider
        value={{
          cellChangesListener: cellChangesListener,
          updateSchedule: updateSchedule,
          saveChanges: saveChangesInSchedule,
          getData: getData,
        }}
      >
        {props.children}
      </ScheduleActionsContext.Provider>
    </ScheduleContext.Provider>
  )
}

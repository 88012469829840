import React, { useContext } from "react"
import { ScheduleContext, ScheduleActionsContext } from "../ScheduleContext"
import HoursWrapper from "./HoursWrapper"

export default function ContextApiTestComponent() {
  const schedule = useContext(ScheduleContext)
  const scheduleActions = useContext(ScheduleActionsContext)
  return (
    <div>
      {schedule &&
        schedule.map((e, i) => {
          return (
            <div key={i} style={{ margin: "20px" }}>
              {e.workerSmallDetails.map((w, j) => {
                return (
                  <div key={j}>
                    <span>{w.name}</span>
                    <div style={{ border: "1px solid magenta" }}>
                      {w.workingHours.length}
                    </div>
                    {
                      <span key={w.workingHours}>
                        {w.workingHours[0]
                          ? w.workingHours.map((h, k) => (
                              <HoursWrapper hours={h}></HoursWrapper>
                            ))
                          : ""}
                      </span>
                    }

                    <button
                      onClick={() => scheduleActions.updateSchedule(e, w)}
                    >
                      set hours
                    </button>
                  </div>
                )
              })}
            </div>
          )
        })}
    </div>
  )
}

import React, { useState, useEffect } from "react"
import ChildRenderTestComp from "./ChildRenderTestComp"
export default function RenderTestComp() {
  const [config, setConfig] = useState({})

  useEffect(() => {
    setConfig({
      workingHours: [
        {
          start: new Date(2021, 1, 2, 8, 0, 0),
          end: new Date(2021, 1, 2, 15, 0, 0),
        },
      ],
      onClickTest: conf => {
        const prev = conf.workingHours
        const next = [...prev, { start: new Date(2025, 1, 2) }]
        conf.workingHours = next
        // conf.componentKey++
        // setComponentKey(prevKey => prevKey + 1)
      },
    })
  }, [])

  return (
    <div>
      {config.workingHours ? (
        <ChildRenderTestComp
          // componentKey={config.hours.length}
          config={config}
        />
      ) : (
        ""
      )}
    </div>
  )
}
